<template>
  <section>
    <b-modal
      v-model="showModal"
      size="md"
      no-close-on-esc
      no-close-on-backdrop
      title="Manage Restrictions"
      class="p-4"
    >
      <template #modal-footer>
        <b-button
          variant="primary"
          @click="submitData"
        >
          <div
            v-if="dataLoad"
            class="spinner"
          >
            <b-spinner
              small
              class="d-inline-block"
            />
            <span class="sr-only">Loading...</span>
          </div>
          Update
        </b-button>
      </template>
      <div class="px-2">
        <b-row
          v-if="!deviceSelectedCategories.length"
          lg="2"
          md="2"
          xl="2"
          sm="2"
          class="my-1 d-flex justify-content-center"
        >
          <span class="label">There are no Restrictions available on this screen</span>
        </b-row>
        <b-row
          v-else
          lg="2"
          md="2"
          xl="2"
          sm="2"
          class="my-1"
        >
          <b-col
            lg="12"
            md="12"
            xl="12"
            sm="12"
          ><span class="label">Selected Restrictions</span></b-col>
          <span
            v-for="(slot) in deviceSelectedCategories"
            :key="slot.id"
            lg="2"
            md="2"
            xl="2"
            sm="2"
            class="pt-1 ml-1"
          >
            <b-badge
              pill
              variant="success"
              style="min-width: 70px;"
            >
              {{ slot.name }}
            </b-badge>
          </span>
        </b-row>
        <b-row class="mt-1">
          <b-col>
            <b-form-group
              text-bolder
              label="Restricted Categories"
              class="label mt-1"
            >
              <v-select
                v-model="deviceSelectedCategories"
                class="zindex-4"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="adCategories"
                placeholder="Select Categories"
                multiple
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <b-overlay
        :show="dataLoad"
        no-wrap
      />
    </b-modal>
  </section>
</template>
<script>
import {
  BModal, BFormGroup, BButton, BOverlay, BSpinner,
  BRow,
  BCol,
  BBadge,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'
import AccountTypes from '@/common/enums/accountTypeEnum'
import { showToast } from '@/common/global/functions'

export default {
  components: {
    BModal,
    BOverlay,
    BButton,
    BFormGroup,
    BSpinner,
    BRow,
    BCol,
    BBadge,
    vSelect,
  },
  emits: ['refresh-data'],
  data() {
    return {
      showModal: false,
      dataLoad: false,
      required,
      AccountTypes,
      deviceData: null,
      adCategories: [],
      deviceSelectedCategories: [],
    }
  },
  computed: {
    userRole() {
      return this.$store.getters['user/getUserRole']
    },
  },
  async mounted() {
    if (this.userRole === this.AccountTypes.ADMIN) {
      this.adCategories = await this.$store.dispatch('pmpAds/getAdminAdCategories')
    } else {
      this.adCategories = await this.$store.dispatch('pmpAds/getAdCategories')
    }
  },
  methods: {
    showOpTimeModal(device) {
      this.showModal = !this.showModal
      this.deviceData = device
      if (this.deviceData) {
        this.deviceSelectedCategories = this.deviceData.device_categories
      }
    },
    onWheel(e) {
      e.target.blur() // This will remove focus from the input element when the wheel event occurs
    },

    async submitData() {
      const categoriesIds = []
      this.deviceSelectedCategories.map(category => categoriesIds.push(category.id))
      try {
        this.dataLoad = true
        if (this.userRole === this.AccountTypes.USER) {
          const response = await this.$store.dispatch('devices/updateDeviceCategories', { category_ids: categoriesIds, mac_address: this.deviceData.mac_address })
          if (response.data.statusCode === 200) {
            this.$emit('refresh-data')
            this.showOpTimeModal()
            showToast('Categories Updated', response.data.message.toString(), 'success')
            this.dataLoad = false
          }
        } else {
          const response = await this.$store.dispatch('devices/updateDeviceCategoriesAdmin', { category_ids: categoriesIds, mac_address: this.deviceData.mac_address })
          if (response.data.statusCode === 200) {
            this.$emit('refresh-data')
            this.showOpTimeModal()
            showToast('Operational Time Updated', response.data.message.toString(), 'success')
            this.dataLoad = false
          }
        }
      } catch (e) {
        this.$swal(e.response.data.message)
        this.dataLoad = false
      }
    },
  },
}
</script>
